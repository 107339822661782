import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ContinueRFQ.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { urlFactory } from 'common/utils/urls';
import history from 'common/utils/history';
import { RFQPriceOfferT } from 'common/store/rfq-details/models';
import { getDateFromISO } from 'common/utils/time';

const cx = classNames.bind(styles);

type PropsT = {
    id: RFQIdT;
    defaultPriceOffer: RFQPriceOfferT | null;
};

const ContinueRFQ: React.FC<PropsT> = React.memo((props) => {
    const { id, defaultPriceOffer } = props;
    const { t } = useTranslation();

    const goToCompleteRFQ = () => {
        const points = defaultPriceOffer?.points || [];
        const pickUpDate = points[0]?.from || null;
        const dropOffDate = points[points.length - 1]?.from || null;

        const url = urlFactory.completeRFQ({
            id,
            offerId: defaultPriceOffer?.id || null,
            pickupDate: getDateFromISO(pickUpDate),
            dropoffDate: getDateFromISO(dropOffDate),
        });
        history.push(url);
    };

    return (
        <Button
            theme={ButtonThemeEnum.primary}
            className={cx('action')}
            onClick={goToCompleteRFQ}
            testSelector="continue"
        >
            {t('common:rfq-details.actions.complete')}
        </Button>
    );
});

export default ContinueRFQ;
